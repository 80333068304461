import store from '@/state/store'

export default [
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        // component: () => import('../views/pages/dashboards/sales/index')
        component: () => import('../views/pages/dashboards/home/index')
    },
    {
        path: '/balance',
        name: 'balance',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/balance/index')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/index')
    },
    {
        path: '/profile-modify',
        name: 'profile_modify',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/form')
    },
    {
        path: '/messages/:id(\\d+)?',
        name: 'messages',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/messages/index')
    },
    {
        path: '/send-sms',
        name: 'send_sms',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sms/send')
    },
    {
        path: '/sent-sms',
        name: 'sent_sms',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sms/out')
    },
    {
        path: '/received-sms',
        name: 'received_sms',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sms/in')
    },
    {
        path: '/api-accounts',
        name: 'api_accounts',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/apiAccount/index')
    },
    {
        path: '/api-accounts/create',
        name: 'api_accounts_create',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/apiAccount/create')
    },
    {
        path: '/api-accounts/:id(\\d+)',
        name: 'api_accounts_edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/apiAccount/form')
    },
    {
        path: '/contact-lists',
        name: 'contact_lists',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/contact/index')
    },
    {
        path: '/contact-lists/:id(\\d+)',
        name: 'contact_lists_edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/contact/edit')
    },
    {
        path: '/addresses/:id(\\d+)',
        name: 'address_edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/contact/address.edit')
    },
    {
        path: '/pricing',
        name: 'pricing',
        alias: ['/prices'],
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/pricing/index')
    },
    {
        path: '/invoices/stripe',
        name: 'stripe',
        meta: {
            authRequired: true,
        },
        props: true,
        component: () => import('../views/pages/invoice/stripe')
    },

    {
        path: '/invoices',
        name: 'invoices',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/invoice/index')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/login/two-factor-authorization/:hash',
        name: 'login-two-factor-auth',
        component: () => import('../views/pages/account/two-factor-authorization.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/login/two-factor-auth/email-sent',
        name: 'login-two-factor-auth-email-sent',
        component: () => import('../views/pages/account/two-factor-auth-email-sent.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password/:hash',
        name: 'reset-password',
        component: () => import('../views/pages/account/reset-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
]
