import { authHeader } from './authservice/auth-header';

export const userService = {
    all,
    one,
    create,
    update,
    destroy,
    saveAlerts,
    saveLocale
};

function all() {
    const endpoint = `/user/crud`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function one(id) {
    const endpoint = `/user/crud/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function create(data) {
    const endpoint = `/user/crud`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function saveAlerts(data) {
    const endpoint = `/user/saveAlerts`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function saveLocale(data) {
    const endpoint = `/user/saveLocale`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function update(id, data) {
    const endpoint = `/user/crud/${id}`;

    if (window.axios) {
        return window.axios.patch(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function destroy(id) {
    const endpoint = `/user/crud/${id}`;

    if (window.axios) {
        return window.axios.delete(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
