import { authHeader } from './authservice/auth-header';

export const invoiceService = {
    all,
    one,
    create,
    update,
    destroy,
    download,
    print,
    deposit,
    paymentIntent,
    successfulPayment
};

function download(id) {
    const endpoint = `/invoice/download/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint, {responseType: 'blob'}).then(response => {
            // window.open(URL.createObjectURL(response.data), '_blank');
            const contDis = response.request.getResponseHeader('Content-Disposition')
            const hasFileName = contDis.indexOf(';') !== -1
            let filename = hasFileName ? contDis.split(';')[1] : id + '.pdf'
            if (hasFileName) {
                filename = filename.substring(filename.indexOf('"') + 1, filename.lastIndexOf('"'))
            }
            const a = document.createElement('a')
            a.href = URL.createObjectURL(response.data)
            a.download = filename
            a.click()
            return response.status;
        }).catch(({ status, data }) => {
            console.log(status, data)
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function print(id) {
    const endpoint = `/invoice/download/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint, {responseType: 'blob'}).then(response => {
            window.open(URL.createObjectURL(response.data), '_blank');
            return response.status;
        }).catch(({ status, data }) => {
            console.log(status, data)
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function all() {
    const endpoint = `/invoice/getList`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response && response.data && response.data.data
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function deposit(data) {
    const endpoint = `/invoice/order`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function paymentIntent(data) {
    const endpoint = `/invoice/paymentIntent`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}
function successfulPayment(data) {
    const endpoint = `/invoice/successfulPayment`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function one(id) {
    const endpoint = `/api/invoices/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function create(data) {
    const endpoint = `/api/invoices`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function update(id, data) {
    const endpoint = `/api/invoices/${id}`;

    if (window.axios) {
        return window.axios.patch(endpoint, data, {
         headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(response => {
            return response.data.data;
        });
    }

    let body = [];

    for (let prop in data) {
        body.push(prop + '=' + encodeURIComponent(data[prop]))
    }

    const requestOptions = {
        method: 'PATCH',
        headers: Object.assign( {'Content-Type': 'application/x-www-form-urlencoded'}, authHeader()),
        body: body.join('&')
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function destroy(id) {
    const endpoint = `/api/invoices/${id}`;

    if (window.axios) {
        return window.axios.delete(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
