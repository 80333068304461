import i18n from "../../i18n";
import moment from "moment/moment";

export const state = {
    languages: [
        {
            flag: require("@/assets/images/flags/us.jpg"),
            language: "en",
            title: "English",
        },
        {
            flag: require("@/assets/images/flags/hungary.jpg"),
            language: "hu",
            title: "Magyar",
        },
    ],
    lang: null,
    flag: null,
    text: null,
    value: null
};

export const mutations = {
    'SET_LANG': function (state, lang) {
        state.lang = lang;
    },
    'SET_FLAG': function (state, flag) {
        state.flag = flag;
    },
    'SET_TEXT': function (state, text) {
        state.text = text;
    },
    'SET_VALUE': function (state, value) {
        state.value = value;
    }
};

export const getters = {
    languages: (state) => state.languages,
    lang: (state) => state.lang,
    flag: (state) => state.flag,
    text: (state) => state.text,
    value: (state) => state.value
};


export const actions = {
    set: function ({commit, state}, locale) {
        const value = state.languages.find(l => l.language === locale);

        if (value) {
            commit('SET_LANG', value.language);
            commit('SET_TEXT', value.title);
            commit('SET_FLAG', value.flag);
            commit('SET_VALUE', value);

            i18n.locale = locale;
            moment.locale(locale);
            window.localStorage.setItem('locale', locale);
            window.sessionStorage.setItem('locale', locale);
        }
    },

    modify: function ({dispatch}, locale) {
        dispatch('set', locale);
        dispatch('user/saveLocale', locale, { root: true });
    },
};