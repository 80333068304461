import { userService } from '@/helpers/user.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    list: [],
    item: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : {}
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    list: function (state) {
        return state.list;
    },
    item: function (state) {
        return JSON.parse(JSON.stringify(state.item));
    }
}
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_LIST': function (state, list) {
        state.list = list;
    },
    'ADD_TO_LIST': function (state, item) {
        state.list.unshift(item);
    },
    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(listed => listed.id === id);

        if (index !== -1) {
            state.list.splice(index, 1);
        }
    },
    'REPLACE_ITEM': function (state, item) {
        const index = state.list.findIndex(listed => listed.id === item.id);

        if (index !== -1) {
            state.list[index] = item;
        }
    },
    'SET_ITEM': function (state, item) {
        state.item = item || {};
    },
    'UPDATE_PROPS': function (state, props) {
        Object.keys(props).forEach(key => {
            state.item[key] = props.key;
        });
    },
}
export const actions = {
    fetchList: function ({commit}) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return userService.all().then(data => {
            commit('SET_LIST', data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchOne: function ({commit}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return userService.one(id).then(data => {
            commit('SET_ITEM', data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    create: function ({commit}, payload) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return userService.create(payload).then(data => {
            const model = Object.assign({}, payload, data);
            commit('SET_ITEM', model);
            commit('ADD_TO_LIST', model);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    update: function ({commit, state}, payload) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        const id = payload.m_id;

        const data = JSON.parse(JSON.stringify(payload));

        delete data.m_id;

        for (let prop in payload) {
            if (state.item[prop] === payload[prop]) {
                delete data[prop]
            }
        }

        return userService.update(id, data).then(item => {
            const model = Object.assign({}, payload, item);
            commit('SET_ITEM', model);
            commit('REPLACE_ITEM', model);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return model;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    saveAlerts: function ({commit, state}, payload) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        const data = JSON.parse(JSON.stringify(payload));

        for (let prop in payload) {
            if (state.item[prop] === payload[prop]) {
                delete data[prop]
            }
        }

        return userService.saveAlerts(data).then(response => {
            const model = Object.assign({}, state.item, payload, response);
            commit('SET_ITEM', model);
            commit('REPLACE_ITEM', model);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return response;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    saveLocale: function ({commit, state}, locale) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);


        return userService.saveLocale({locale: locale}).then(response => {
            const model = Object.assign({}, state.item, {locale: locale}, response);
            commit('SET_ITEM', model);
            commit('REPLACE_ITEM', model);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return response;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    destroy: function ({commit}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return userService.destroy(id).then(data => {
            commit('SET_ITEM', {});
            commit('REMOVE_ITEM', id);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    }
};
