import Vue from 'vue'
import './plugins/axios'
import BootstrapVue from 'bootstrap-vue'
import vco from "v-click-outside"

import App from './App.vue'
import router from './router'
import VueFeather from 'vue-feather'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueEasyLightbox from 'vue-easy-lightbox'
import VueAlertify from "vue-alertify"
import VueMask from 'v-mask'
import VueQuillEditor from 'vue-quill-editor'
import * as VueGoogleMaps from 'vue2-google-maps'
// import VueVectorMap from 'vuevectormap'
import i18n from './i18n'
import moment from "moment";
import VueTelInput from 'vue-tel-input';

import store from '@/state/store'

String.prototype.truncate = function (length, separator = '...') {
  if (!this || (this && this.length < length)) {
    return this
  }

  const text = this.substring(0, length)
  let index = text.lastIndexOf(' ')
  let space = ' '

  if (index === -1) {
    index = length
    space = ''
  }

  return text.substring(0, index) + space + separator;
};

String.prototype.phone = function () {
  if (!this || !this.length) {
    return this;
  }

  switch (this.length) {
    case 11:
    case 12:
      return this.replace(/^\+?([\d]{2})([\d]{2})([\d]{3})([\d]{2})([\d]{2})/g, '(+$1/$2) $3-$4-$5');
    default:
      return this;
  }
};

// import { initFirebaseBackend } from './authUtils'

// import { configureFakeBackend } from './helpers/fake-backend';

/*if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}*/

/*const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};*/

Vue.config.productionTip = false

import 'vue-tel-input/dist/vue-tel-input.css';
import '@/assets/scss/app.scss';

Vue.use(VueTelInput);
Vue.use(VueFeather);
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueEasyLightbox)
Vue.use(VueAlertify)
Vue.use(VueMask)
Vue.use(VueQuillEditor)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
// Vue.use(VueVectorMap)

Vue.prototype.$currencyFormatter = new Intl.NumberFormat('hu-HU', {
  style: 'currency',
  currency: 'HUF',
  roundingMode: 'floor',
  maximumFractionDigits: 0
});

Date.prototype.formattedDate = function () {
  const pad = (number) => {
    return number < 10 ? '0' + String(number) : number
  }

  return [this.getFullYear(), pad(this.getMonth()+1), pad(this.getDate())].join('-')
      + ' ' +
      [pad(this.getHours()), pad(this.getMinutes()), pad(this.getSeconds())].join(':');

};

const locale = window.sessionStorage.getItem('locale') || window.localStorage.getItem('locale') || window.navigator.language.substring(0, 2);

if (locale) {
  i18n.locale = locale;
  moment.locale(locale);
}

const routes = router.options.routes;
const aliases = i18n.t('routes');

for (let prop in aliases) {
  routes.map(route => {
    if (prop === route.name) {
      if (route.alias && route.alias.length && !route.alias.includes(aliases[prop])) {
        route.alias = [...route.alias, aliases[prop]]
      } else if (!route.alias || (route.alias && !route.alias.length)) {
        route.alias = [aliases[prop]];
      }
      router.addRoute(route);
    }
  })
}

const mixins = {
  methods: {
    setTitle: function (title) {
      return location.hostname + ' | ' + this.$t('sms.send_sms') + ' | ' + title
    },
  }
}

Vue.mixin(mixins);

window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
